const config = {
  siteName: '微勃',
  name: 'sm',
  enName: 'SEME',
  title: '微勃',
  site: '',
  favicon: require('./img/favicon.ico').default,
  description: '微勃',
  keywords: '微勃',
  url: 'https://p1.qy2mb.vip',
  // 主题颜色
  // 底部icon 颜色，因为太定制化了 暂时用配置的方法 切换主题时在此处指定色值
  themes: {
    primaryColor: '#ffe3aa', // 主题色纸
    footerSVG: {
      defaultBgColor: '#868686', // 背景次要文字
      activeBgColor: '#ffe3aa', // 主题色纸
      defaultIconColor: '#1b1b1b', // 一级背景色
      activeIconColor: '#fff' // 白色
    }
  }
}
export default config
